import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        reachTheBottom: "",
    },
    mutations: {
        // 保存当前菜单栏的路径
        ReachTheBottom(state, ReachTheBottom) {
            state.reachTheBottom = ReachTheBottom;
        }
    }
})
