const {PORT} = require("../devServerPort");
const isProduction = process.env.NODE_ENV === "production"
module.exports = {
    isProduction,
    title: '',

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: false,
    vconsole: false,
    needPageTrans: true,

    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: false,
    defaultAvatar: 'https://astl.oss-cn-beijing.aliyuncs.com/h5/wx/269961584330905_.pic_hd.jpg',
    defaultArticleCoverUrl:'https://astl.oss-cn-beijing.aliyuncs.com/h5/wx/269961584330905_.pic_hd.jpg',
    appHome: isProduction
        ? 'https://yaolingh5.magical-light.com/'
        : 'http://localhost:' + PORT,
}
