/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = 'https://yaolingapi.magical-light.com/api';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class SignInForCService {
  /**
   * 新建数据
   */
  static addLog(
    params: {
      /**  */
      signInId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/signIn/addLog';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { signInId: params['signInId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据ID获取详情数据
   */
  static signIn(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/signIn/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ExaminationResultForCService {
  /**
   * 完成答题
   */
  static finish(
    params: {
      /**  */
      examinationId: number;
      /**  */
      resultId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/examinationResult/finish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { examinationId: params['examinationId'], resultId: params['resultId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 答题
   */
  static answer(
    params: {
      /**  */
      questionId: number;
      /**  */
      resultId: number;
      /**  */
      answer: string;
      /**  */
      isRight: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/examinationResult/answer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        questionId: params['questionId'],
        resultId: params['resultId'],
        answer: params['answer'],
        isRight: params['isRight']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 新建数据
   */
  static add(
    params: {
      /**  */
      examinationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/examinationResult/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { examinationId: params['examinationId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据ID获取详情数据
   */
  static examinationResult(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/examinationResult/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据ID删除数据
   */
  static examinationResult1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/examinationResult/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ExaminationForCService {
  /**
   * 阅读
   */
  static read(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/examination/read';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据ID获取详情数据
   */
  static examination(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/examination/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CloudCourseForCService {
  /**
   * 分享文章
   */
  static share(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/cloudCourse/share';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交阅读结束的时间
   */
  static readEnd(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/cloudCourse/readEnd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 给文章点赞
   */
  static like(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/cloudCourse/like';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据ID获取详情数据
   */
  static cloudCourse(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/cloudCourse/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表分页数据
   */
  static list(
    params: {
      /**  */
      queryDto: CloudCourseDTO;
      /**  */
      pagination: Pagination;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/cloudCourse/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { queryDto: params['queryDto'], pagination: params['pagination'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AuthForCService {
  /**
   *
   */
  static loginByExternalId(
    params: {
      /**  */
      externalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/auth/loginByExternalId';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { externalId: params['externalId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/auth/getUserInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CloudCourseMenuForCService {
  /**
   * 获取树结构列表数据
   */
  static tree(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/cloudCourseMenu/tree';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表分页数据
   */
  static list(
    params: {
      /**  */
      queryDto: CloudCourseMenuDTO;
      /**  */
      pagination: Pagination;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/c/cloudCourseMenu/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { queryDto: params['queryDto'], pagination: params['pagination'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface Pagination {
  /**  */
  pageIndex?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalCount?: number;

  /**  */
  orderBy?: string;

  /**  */
  totalPage?: number;
}

export interface CloudCourseMenuDTO {
  /**  */
  createTime?: Date;

  /** 名称 */
  name?: string;

  /** 顺序 */
  sequence?: number;

  /** 父级 */
  parentId?: number;

  /**  */
  parentName?: string;
}

export interface CloudCourseDTO {
  /**  */
  createTime?: Date;

  /** 作者 */
  author?: string;

  /**  */
  title?: string;

  /** 封面图片 */
  coverUrl?: string;

  /**  */
  showTime?: Date;

  /** 云课堂目录 */
  cloudCourseMenuId?: number;

  /** 内容 */
  content?: string;

  /** 是否上架 */
  isOnline?: boolean;

  /** 课程类型 */
  courseType?: number;

  /** 视频地址 */
  videoUrl?: string;

  /** 直播地址 */
  liveUrl?: string;

  /** 阅读次数 */
  readCount?: number;

  /** 阅读人数 */
  readerCount?: number;

  /** 喜爱人数 */
  likeCount?: number;

  /** 目录根节点ID */
  menuRootId?: number;

  /**  */
  fileUuidList?: string[];

  /**  */
  cloudCourseMenuName?: string;

  /**  */
  showTimeEnd?: Date;
}
