import * as store from 'store';
import {defaultAvatar} from "../settings";
import {IUserInfo} from "../../types";
import {setAuthToken} from "@/api/request";

export const LOCAL_KEYS = {
    USERINFO: 'user-info',
    AUTH_TOKEN: 'x-auth-token'
}

/**
 *
 * @param userInfo {IUserInfo}
 */
export function storeUserInfo(userInfo: IUserInfo) {
    if (!userInfo.avatar) {
        userInfo.avatar = defaultAvatar
    }
    store.set(LOCAL_KEYS.USERINFO, userInfo)
}

/**
 * @returns {IUserInfo|undefined}
 */
export function getStoredUserInfo() {
    return store.get(LOCAL_KEYS.USERINFO)
}


export function removeStoredUserInfo() {
    store.remove(LOCAL_KEYS.USERINFO)
}


/**
 *
 * @returns {string|undefined}
 */
export function getStoredUserInfoId(): string | undefined {
    const userInfo = getStoredUserInfo();
    return userInfo ? userInfo.userid : undefined
}


/**
 *
 * @returns {string | undefined}
 */
export function getAuthorizedToken() {
    return store.get(LOCAL_KEYS.AUTH_TOKEN)
}

/**
 * save token in local and set auth token header
 * @param token {string}
 */
export function setAuthorizedToken(token: string) {
    store.set(LOCAL_KEYS.AUTH_TOKEN, token)
    setAuthToken(token);
}

export function clearAuthorizedToken() {
    store.remove(LOCAL_KEYS.AUTH_TOKEN)
}
