import 'lib-flexible'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './common/js/permission'
import '../src/assets/css/all.css'
// @ts-ignore
import VueScroller from 'vue-scroller'
import {Common} from "./core/base";
import store from './store/store'
import '@vant/touch-emulator';
import VConsole from 'vconsole';
import {
    ActionSheet,
    AddressEdit,
    Button,
    CellGroup,
    Checkbox,
    CheckboxGroup,
    Col,
    Dialog,
    Empty,
    Field,
    Icon,
    Image as VanImage,
    Lazyload,
    Loading,
    Notify,
    Overlay,
    Picker,
    Popup,
    Radio,
    RadioGroup,
    Row,
    Stepper,
    Swipe,
    SwipeItem,
    Switch,
    Tab,
    Tabs,
    Toast,
    TreeSelect,
    Uploader,
} from 'vant'
import {initAxiosInstance, IRequest, request} from "./api/request";

if (localStorage.getItem("vconsole")) {
    // 手机调试插件
    Vue.prototype.$vconsole = new VConsole();
}

initAxiosInstance();
Vue.prototype.$request = request;
//@ts-ignore
Vue.prototype.$wx = window.wx
Vue.prototype.$common = Common
Vue.config.productionTip = false
Vue.use(VueScroller)
Vue.use(Lazyload, {
    lazyComponent: true,
})
Vue.use(Picker)
Vue.use(Switch)
Vue.use(Stepper)
Vue.use(AddressEdit)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Uploader)
Vue.use(ActionSheet)
Vue.use(Button)
Vue.use(Overlay)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Row)
Vue.use(TreeSelect)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VanImage)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Notify)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Loading)
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
