import router from '@/router'
import axios from 'axios'

// 创建axios实例

export const axiosClient = axios.create({
    timeout: 60000,                 // 请求超时时间
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json'
    }
})

// request拦截器
// axiosClient.interceptors.request.use((requestConfig)=>{
//     const token= getAuthorizedToken();
//     if(token){
//         requestConfig.headers["authorization"]=" getAuthorizedToken();"
//
//     }
// },(err)=>{
//     return Promise.reject(err)
// })
// respone拦截器
axiosClient.interceptors.response.use(
    response => {
        let result = response.data
        console.log("result,result", result)
        if (result.code !== 0) {
            switch (result.code) {
                case 211:
                    router.push('/login')
                    result = null
                    break
                case 501:
                    window.open('/#/404', '_self')
                    break
                case 401:
                    router.push('/404')
                    break
            }
            const error = new Error(result.msg)
            //@ts-ignore
            error.msg = result.msg
            return Promise.reject(error)
        } else {
            if (result.page && result.data) {
                result.data.page = result.page
            }
            return result
        }
    },
    error => {
        if (error.message.indexOf('15000ms') !== -1) {
            alert('请求超时，请检查网络或尝试刷新页面')
        } else {
            console.log(error)
        }
        return Promise.reject(error)
    }
)
