import defaultSettings from '../../settings'
import {Route} from "vue-router";

const title = defaultSettings.title

export default function getPageTitle(route: Route) {
    const pageTitle = route.meta ? route.meta.title : route.name;
    if (pageTitle) {
        return `${pageTitle}`
    }
    return `${title}`
}
