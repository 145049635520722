import {Store} from "vuex";

function throttle(fn: Function, interval = 300) {
    let canRun = true;
    return function () {
        // eslint-disable-next-line prefer-rest-params
        const args = [...arguments]
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
            //@ts-ignore
            fn.apply(this, ...args);
            canRun = true;
        }, interval);
    }
}

export function patch(store: Store<any>) {
    window.onscroll = throttle(onScroll)

    function onScroll() {
        //变量scrollTop是滚动条滚动时,距离顶部的距离
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight == scrollHeight) {
            //写后台加载数据的函数
            store.commit('ReachTheBottom', true)
        } else {
            if (store.state.reachTheBottom) {
                store.commit('ReachTheBottom', false)
            }
        }
    }

    //函数节流

}
