<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>
<script>

import {patch} from "./utils/scroll.patch";

export default {
  name: 'App',
  beforeMount() {
    // console.log("this.$route.query.token", this.$route.query.token)
    this.initApp();
  },
  data(){
      return {
        isAppReady:false,
      }
  },
  mounted() {
    patch(this.$store)
  },
  methods: {
    async initApp() {
      this.isAppReady=true;
    }
  }
}
</script>

<style lang="scss">
#udesk_container {
  display: none;
}

._v-container {
  margin: 0 auto;
  max-width: 750px;
  left: auto !important;
}

#app {
  margin: 0 auto;
  max-width: 750px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
  background: #F5F6FA;
}

</style>
