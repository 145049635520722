import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path:"/",
        index:true,
        component:()=>import('../views/cloudCourse/classroomContentList.vue'),
    },
    {
        path: '/answer/:id/:resultId',
        name: '测试题',
        component: () => import('../views/detail/base/answer.vue'),
    },
    {
        path: '/cloudCourse/:id',
        name: 'cloudCourse',
        component: () => import('../views/cloudCourse/classroomDetail.vue'),
        meta: {
            title: '云课堂'
        }
    },
    {
        path: '/examination/:id',
        name: 'examination',
        component: () => import('../views/cloudCourse/examination.vue'),
        meta: {
            title: '测试',
        }
    },
    {
        path: '/cloudCourse/:parentId',
        name: '云课堂内容列表',
        component: () => import('../views/cloudCourse/classroomContentList.vue'),
        meta: {
            title: '云课堂内容列表',
        }
    },
    {
        path: '/cloudCourses',
        name: '云课堂内容列表',
        component: () => import('../views/cloudCourse/classroomContentList.vue'),
        meta: {
            title: '云课堂内容列表',
        }
    },
    {
        path:'/403',
        name:'403',
        component:()=>import("../views/403.vue"),
        meta:{
            isPublic:true
        }
    },
    {
        path:'*',
        name:'404',
        component:()=>import("../views/404.vue"),
        meta:{
            isPublic:true
        }
    }
]
const router = new VueRouter({
    routes
})

export default router
