import router from '@/router'
import {Dialog} from 'vant'
import getPageTitle from './get-page-title'
import getPageURL from "./getPageURL";
import {
    clearAuthorizedToken,
    getAuthorizedToken,
    getStoredUserInfo,
    removeStoredUserInfo,
    setAuthorizedToken,
    storeUserInfo
} from "@/utils/storage";
import {AuthForCService} from "@/service";

function parseOpenIdFromURL() {
    const url = new URL(window.location.href)
    return url.searchParams.get('userId')
}


async function redirect403Page() {
    await router.replace("/403")
}

async function loginByCodeInURL(externalId: string, nextPath: string) {
    let newToken;
    try {
        newToken = await AuthForCService.loginByExternalId({externalId})
        setAuthorizedToken(newToken)
    } catch (e) {
        // // code expired.
        // if (!localStorage.getItem('code_failed')) {
        //     localStorage.setItem('code_failed', new Date().getTime().toString())
        //     // 重新重定向去拿code
        //     await redirect403Page()
        //     console.log('code 失效 重新获取')
        //     throw e
        // }

        //@ts-ignore
        const message = e.toString() || "授权失败请稍后再试"
        localStorage.removeItem('code_failed')
        await Dialog.alert({
            message
        })
        clearAuthorizedToken();
        removeStoredUserInfo();
        console.log("失败了 第二次了，不获取了")
        //@ts-ignore
        throw new Error(e)
    }

    if (newToken) {
        try {
            const userInfo = await AuthForCService.getUserInfo()
            storeUserInfo(userInfo)
            localStorage.removeItem('code_failed')
        } catch (e) {
            await Dialog.alert({message: '获取用户信息失败'})
            clearAuthorizedToken();
            removeStoredUserInfo();
            const url = new URL(window.location.href)
            url.searchParams.set('userId', '')
            window.location.replace(url.toString())
            console.log("获取用户信息失败")
            throw e
        }
    }
    localStorage.removeItem('login_callback_url')
    window.location.replace(getPageURL(nextPath))
}

router.beforeEach(async (to, from, next) => {
    const nextPath = to.fullPath;
    document.title = getPageTitle(to)
    //@ts-ignore
    const isRouteRequireLogin = !to.meta.isPublic;
    const hasUserInfo = getStoredUserInfo();
    const hasToken = getAuthorizedToken();
    const needLogin = !hasUserInfo || !hasToken;

    const externalId = parseOpenIdFromURL()

    if (externalId) {
        console.log("nextPath",nextPath)
        return loginByCodeInURL(externalId, nextPath)
    }

    if (isRouteRequireLogin && needLogin) {
        // const tokenFromURL = 'amxBdFFGRHpKKzB0eWFyS29nRzhRRlNBRVdnKzdDaTZ1akJTYVdsYk1iRT0='
        // if (tokenFromURL) {
        //     setAuthorizedToken(decodeTokenParams(tokenFromURL))
        // }
        const storedToken = getAuthorizedToken();
        if (storedToken) {
            try {
                const userInfo = await AuthForCService.getUserInfo()
                storeUserInfo(userInfo);
                return next()
            } catch (e) {
                await Dialog.alert({
                    message: '请重新登录'
                })
                clearAuthorizedToken();
                removeStoredUserInfo();
                window.location.replace("/")
                throw e;
            }
        }
        if (!externalId) {
            localStorage.setItem('login_callback_url', nextPath)
            clearAuthorizedToken();
            removeStoredUserInfo();
            await redirect403Page()
            console.log('no userId in url')
            throw new Error('no code in url')
        }
        return loginByCodeInURL(externalId, nextPath)
            .then(next, (error) => {
                console.log(error)
            })
    }

    next()
})
