import {
    serviceOptions,

    CloudCourseForCService,
    CloudCourseMenuForCService,
    ExaminationForCService,
    ExaminationResultForCService,
    SignInForCService,
    AuthForCService
} from '../service/index';
import {axiosClient} from "./axios-client";
import {getAuthorizedToken} from "@/utils/storage";

export function initAxiosInstance() {
    serviceOptions.axios = axiosClient
    serviceOptions.axios.interceptors.request.use((config) => {
        const token = getAuthorizedToken()
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // rewrite params due to bad generation
        if (config.method?.toUpperCase() === "GET") {
            if (config.params) {
                if (config.params.pagination || config.params.queryDto) {
                    config.params = {
                        ...config.params.pagination,
                        ...config.params.queryDto
                    }
                }
            }
        }
        return config;
    })
}

export function setAuthToken(token: string) {
    if (!serviceOptions.axios) {
        throw new Error("Invalid axios instance")
    }

}

export const request = {
    CloudCourseForCService,
    CloudCourseMenuForCService,
    ExaminationForCService,
    ExaminationResultForCService,
    SignInForCService,
    AuthForCService
}
export type IRequest = typeof request;
